// extracted by mini-css-extract-plugin
export var android = "comingSoon-module--android--a2e1e";
export var appBg = "comingSoon-module--appBg--74943";
export var appMockup = "comingSoon-module--appMockup--0fa83";
export var appMockupImg = "comingSoon-module--appMockupImg--60a5d";
export var appText = "comingSoon-module--appText--d0dff";
export var buttonBottomText = "comingSoon-module--buttonBottomText--44acb";
export var buttonTopText = "comingSoon-module--buttonTopText--c06a5";
export var buttons = "comingSoon-module--buttons--09b16";
export var comingSoonBody = "comingSoon-module--comingSoonBody--f59a0";
export var comingSoonTitle = "comingSoon-module--comingSoonTitle--c1091";
export var container = "comingSoon-module--container--0271e";
export var frame = "comingSoon-module--frame--f895f";
export var gatsbyImage = "comingSoon-module--gatsbyImage--1589e";
export var icon = "comingSoon-module--icon--ea5e5";
export var ios = "comingSoon-module--ios--bd2bc";