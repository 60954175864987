import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Container, Col, Row } from "react-bootstrap";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./success.module.scss";

function SuccessSection({
  imgLeft,
  imgLeftMobile,
  centeredImg,
  centeredImgMobile,
  imgRight,
  imgRightMobile,
  title,
  subtitle,
  btnDownload,
}) {
  return (
    <Container
      fluid
      className={`${styles.success} d-flex justify-content-center align-items-center`}
    >
      <Row
        className={`${styles.successAlign} d-flex justify-content-between align-items-center flex-wrap`}
      >
        <Col
          lg={2}
          md={3}
          sm={2}
          xs={2}
          className="no-padding no-margin order-md-1"
        >
          <div
            className={`${styles.firstWrap} d-flex justify-content-start align-items-end`}
          >
            <img className={styles.webWrap} {...getImgProps(imgLeft.file)} />
            <img
              className={`${styles.mobileWrap}`}
              {...getImgProps(imgLeftMobile)}
            />
          </div>
        </Col>
        <Col
          lg={4}
          md={12}
          sm={12}
          xs={12}
          className="no-margin no-padding order-lg-2 order-md-4 order-sm-4 order-4"
        >
          <div
            className={`${styles.titleSuccess} d-flex flex-column no-margin no-padding`}
          >
            <p>{title}</p>
            <span>{subtitle}</span>
            <AnchorLink
              to="/#get-app-section"
              className={`${styles.btnDownload} d-flex justify-content-center align-items-center`}
            >
              {btnDownload}
            </AnchorLink>
          </div>
        </Col>
        <Col
          lg={4}
          md={6}
          sm={8}
          xs={8}
          className="no-padding no-margin order-lg-3 order-md-2 order-sm-2 order-2"
        >
          <div
            className={`${styles.emptyWrap} d-flex justify-content-center align-items-center`}
          >
            <img
              className={styles.webWrap}
              {...getImgProps(centeredImg.file)}
            />
            <img
              className={`${styles.mobileWrap}`}
              {...getImgProps(centeredImgMobile)}
            />
          </div>
        </Col>
        <Col
          lg={2}
          md={3}
          sm={2}
          xs={2}
          className="no-padding no-margin order-lg-4 order-md-3 order-sm-3 order-3"
        >
          <div
            className={`${styles.lastWrap} d-flex justify-content-end align-items-center`}
          >
            <img className={styles.webWrap} {...getImgProps(imgRight.file)} />
            <img
              className={styles.mobileWrap}
              {...getImgProps(imgRightMobile)}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SuccessSection;
