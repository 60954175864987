import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";
import MenuComponent from "./menuComponent";
import * as styles from "./menu.module.scss";

function MenuSection({ menuList, text, bgTop, bgBottom }) {
  const wrapList = sortArrayOfObjects(
    menuList.filter(({ type }) => {
      return type === "wrap";
    })
  );
  const bowlList = sortArrayOfObjects(
    menuList.filter(({ type }) => {
      return type === "bowl";
    })
  );
  return (
    <>
      <Container
        fluid
        className={styles.menuTop}
        style={{
          backgroundImage: "url(" + bgTop.file.localFile.url + ")",
        }}
      ></Container>
      <Container fluid className={styles.menu} id="menu-section">
        <Row>
          <Col
            lg={{ span: 2, offset: 2 }}
            md={{ span: 1, offset: 1 }}
            className="no-padding no-margin"
          ></Col>
          <Col lg={5} md={8}>
            <p className={styles.menuTitle}>{text.title}</p>
            <p className={styles.menuDescription}>{text.body}</p>
          </Col>
        </Row>
        <Row className="no-padding no-margin">
          <div className={styles.wrapContainer}>
            <Col lg={{ span: 4, offset: 4 }} md={{ span: 2, offset: 2 }}></Col>
            <Col
              lg={8}
              className={`${styles.wraps} d-flex justify-content-end align-items-center`}
            >
              <MenuComponent title="Wraps" dataList={wrapList} />
            </Col>
          </div>
        </Row>
        <Row className="no-padding no-margin">
          <div className={styles.bowlContainer}>
            <Col
              lg={8}
              className={`${styles.bowls} d-flex justify-content-start align-items-center`}
            >
              <MenuComponent title="Bowls" dataList={bowlList} />
            </Col>
            <Col lg={{ span: 4, offset: 4 }} md={{ span: 2, offset: 2 }}></Col>
          </div>
        </Row>
      </Container>
      <Container
        fluid
        className={styles.menuBottom}
        style={{
          backgroundImage: "url(" + bgBottom.file.localFile.url + ")",
        }}
      ></Container>
    </>
  );
}

export default MenuSection;
