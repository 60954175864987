import React from "react";
import SuccessSection from "./successSection";
import MissionSection from "./missionSection";
import SpecialSection from "./specialSection";
import MenuSection from "./menuSection";
import ComingSoonSection from "./comingSoonSection";
import LocationsSection from "./locationsSection";

function Main({ Success, Mission, Special, Menu, Location, App }) {
  return (
    <>
      <SuccessSection {...Success} />
      <MissionSection {...Mission} />
      <SpecialSection {...Special} />
      <MenuSection {...Menu} />
      <ComingSoonSection {...App} />
      <LocationsSection {...Location} />
    </>
  );
}

export default Main;
