import React from "react";
import { graphql } from "gatsby";
import Main from "layouts/main";
import Layout from "components/Layout";
import Seo from "components/Seo";
import { getMainPageProps } from "helpers/getMainPageProps";

function App({ data }) {
  return (
    <Layout>
      <Seo seo={{ metaTitle: "eatn’act:  balanced food restaurant" }} />
      <Main {...getMainPageProps(data)} />
    </Layout>
  );
}

export const query = graphql`
  query homePageData {
    strapiHomePage {
      Success {
        btnDownload
        title
        subtitle
        imgLeft {
          file {
            localFile {
              url
            }
            alternativeText
          }
        }
        imgRight {
          file {
            alternativeText
            localFile {
              url
            }
          }
        }
        centeredImg {
          file {
            alternativeText
            localFile {
              url
            }
          }
        }
        centeredImgMobile {
          alternativeText
          localFile {
            url
          }
        }
        imgLeftMobile {
          alternativeText
          localFile {
            url
          }
        }
        imgRightMobile {
          alternativeText
          localFile {
            url
          }
        }
      }
      Mission {
        bgTop {
          file {
            alternativeText
            localFile {
              url
            }
          }
        }
        missionImg {
          file {
            alternativeText
            localFile {
              url
            }
          }
        }
        title
        subtitle
        body
      }
      Special {
        title
        specialImg {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        specialComponent {
          title
          body
          order
          icon {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      Menu {
        text {
          title
          body
        }
        bgTop {
          file {
            alternativeText
            localFile {
              url
            }
          }
        }
        bgBottom {
          file {
            alternativeText
            localFile {
              url
            }
          }
        }
        menuList {
          cal
          description
          order
          title
          type
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      App {
        content {
          title
          body
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Location {
        title {
          title
          body
        }
        address {
          title
          body
        }
        addressImg {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        branches {
          address
          branchName
          phone
          map {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        mapPointer {
          alternativeText
          localFile {
            url
          }
        }
        mapPointerMobile {
          alternativeText
          localFile {
            url
          }
        }
        map {
          localFile {
            url
          }
        }
      }
    }
  }
`;

export default App;
