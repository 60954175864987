import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImageProps } from "helpers/getImgProps";
import * as styles from "./specialComponent.module.scss";

const SpecialComponent = ({ icon, title, description }) => {
  return (
    <div
      className={`${styles.specialContainer} d-flex flex-column flex-md-row justify-content-md-start align-items-center`}
    >
      <div
        className={`${styles.specialIcon} d-flex justify-content-end align-items-center`}
      >
        <div
          className={`${styles.iconContainer} d-flex justify-content-center align-items-center`}
        >
          <GatsbyImage {...getImageProps(icon)} />
        </div>
      </div>
      <div className={styles.specialText}>
        <p className={`${styles.title}`}>{title}</p>
        <p className={`${styles.textDescription} dark fw-400 fs-14 `}>
          {description}
        </p>
      </div>
    </div>
  );
};

export default SpecialComponent;
