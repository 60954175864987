import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getImgProps, getImageProps } from "helpers/getImgProps";
import * as styles from "./location.module.scss";
import addressImg from "images/addressImg.png";
// import { GatsbyImage } from "gatsby-plugin-image";

const mapPointeStyles = {
  AUA: {
    zIndex: 9999,
    position: "absolute",
    top: "53%",
    transform: "translateX(-85px)",
  },
  Cascade: {
    zIndex: 9999,
    position: "absolute",
    top: "46%",
    left: "46%",
    transform: "translateX(-85px)",
  },
};
function LocationsSection({
  // addressImg,
  mapPointer,
  mapPointerMobile,
  title,
  branches,
}) {
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const AUA = branches?.findIndex(
    ({ branchName }) => branchName === "AUA Branch"
  );
  const Cascade = branches?.findIndex(
    ({ branchName }) => branchName === "Cascade Branch"
  );
  branches[AUA].styles = mapPointeStyles["AUA"];
  branches[Cascade].styles = mapPointeStyles["Cascade"];

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 480px)");

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mobileMediaQuery.addEventListener("change", handleMediaQueryChange);

    setIsMobile(mobileMediaQuery.matches);

    return () => {
      mobileMediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const handleAddressClick = (index) => {
    setSelectedAddress(index);
  };

  return (
    <Container fluid className={styles.locations}>
      <Row>
        <Col lg={2} md={2} className="no-padding no-margin"></Col>
        <Col lg={5} md={7}>
          <p className={styles.title}>{title.title}</p>
          <p className={styles.description}>{title.body}</p>
        </Col>
      </Row>
      <Row
        className={`${styles.mapAndAddress} d-flex flex-column flex-md-row justify-content-md-start align-itmes-md-center no-padding no-margin`}
      >
        <Col lg={6} md={12} sm={12} xs={12} className="no-padding no-margin">
          <div
            className={styles.map}
            style={{
              backgroundImage: `url(${branches[selectedAddress].map.localFile.url})`,
            }}
          >
            {isMobile ? (
              <img
                {...getImgProps(mapPointerMobile)}
                style={{ ...branches[selectedAddress].styles, left: "68%" }}
              />
            ) : (
              <img
                {...getImgProps(mapPointer)}
                style={{ ...branches[selectedAddress].styles }}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className={`${styles.mapAddressInfo} d-flex flex-column align-items-md-center justify-content-md-center`}
          >
            {/* <GatsbyImage
              className={styles.addressImg}
              {...getImageProps(addressImg)}
            /> */}
            <img src={addressImg} alt="address" className={styles.addressImg} />
            <div className={styles.addressList}>
              {branches?.map((addressItem, index) => (
                <div
                  key={index}
                  className={`${styles.address} ${
                    selectedAddress === index ? styles.selected : ""
                  }`}
                  onClick={() => handleAddressClick(index)}
                >
                  <p className={styles.branchName}>{addressItem.branchName}</p>
                  <p className={styles.title}>{addressItem.address}</p>
                  <p className={styles.phone} x-apple-data-detectors="false">
                    {addressItem.phone}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default LocationsSection;
