import React, { useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImgProps, getImageProps } from "helpers/getImgProps";
import * as styles from "./menuComponent.module.scss";

function MenuComponent({ title, dataList }) {
  const [currentWrap, setCurrentWrap] = useState(0);
  const [currentBowl, setCurrentBowl] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);

  useEffect(() => {
    autoPlay &&
      setTimeout(() => {
        slideBowlRight();
        slideWrapRight();
      }, 6000);
  });

  const slideWrapRight = () => {
    setCurrentWrap(currentWrap === dataList.length - 1 ? 0 : currentWrap + 1);
  };
  const slideBowlRight = () => {
    setCurrentBowl(currentBowl === dataList.length - 1 ? 0 : currentBowl + 1);
  };

  return (
    <>
      {title === "Wraps" ? (
        <div role="button" tabIndex={0} className={styles.carousel}>
          <div className={styles.carouselWrapper}>
            {dataList.map(({ title, description, cal, image }, index) => {
              return (
                <div
                  key={index}
                  className={
                    index === currentWrap
                      ? `${styles.wrapCarousel} ${styles.wrapCarouselActive}`
                      : `${styles.wrapCarousel}`
                  }
                >
                  <div className={styles.menuInfoWraps}>
                    <p className={styles.title}>{title}</p>
                    <p className={styles.description}>{description}</p>
                    <p className={styles.cal}>{cal}</p>
                    <div className="d-flex justify-content-end align-items-center">
                      {dataList.map((_, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => setCurrentWrap(index)}
                            className={
                              index === currentWrap
                                ? `${styles.seeOtherWrap} ${styles.active}`
                                : `${styles.seeOtherWrap}`
                            }
                          ></span>
                        );
                      })}
                    </div>
                  </div>
                  <div className={styles.menuImg}>
                    <GatsbyImage {...getImageProps(image)} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div role="button" tabIndex={0} className={styles.carousel}>
          <div className={styles.carouselWrapper}>
            {dataList.map(({ image, title, description, cal }, index) => {
              return (
                <div
                  key={index}
                  className={
                    index === currentBowl
                      ? `${styles.bowlCarousel} ${styles.bowlCarouselActive}`
                      : `${styles.bowlCarousel}`
                  }
                >
                  <div className={styles.menuImg}>
                    <GatsbyImage {...getImageProps(image)} />
                  </div>
                  <div className={styles.menuInfoBowls}>
                    <p className={styles.title}>{title}</p>
                    <p className={styles.description}>{description}</p>
                    <p className={styles.cal}>{cal}</p>
                    <div className="d-flex justify-content-start align-items-center">
                      {dataList.map((_, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => setCurrentBowl(index)}
                            className={
                              index === currentBowl
                                ? `${styles.seeOtherWrap} ${styles.active}`
                                : `${styles.seeOtherWrap}`
                            }
                          ></span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default MenuComponent;
