// extracted by mini-css-extract-plugin
export var active = "menuComponent-module--active--2fcfd";
export var bowlCarousel = "menuComponent-module--bowlCarousel--46d77";
export var bowlCarouselActive = "menuComponent-module--bowlCarouselActive--b9c8b";
export var cal = "menuComponent-module--cal--1332e";
export var carousel = "menuComponent-module--carousel--94162";
export var carouselWrapper = "menuComponent-module--carouselWrapper--252de";
export var description = "menuComponent-module--description--52829";
export var fadeInDown = "menuComponent-module--fadeInDown--8d23b";
export var fadeInLeft = "menuComponent-module--fadeInLeft--c5067";
export var fadeInRight = "menuComponent-module--fadeInRight--f734f";
export var menuImg = "menuComponent-module--menuImg--b183f";
export var menuInfoBowls = "menuComponent-module--menuInfoBowls--2cbf4";
export var menuInfoWraps = "menuComponent-module--menuInfoWraps--0a622";
export var seeOtherWrap = "menuComponent-module--seeOtherWrap--f68f9";
export var title = "menuComponent-module--title--c024e";
export var wrapCarousel = "menuComponent-module--wrapCarousel--717d0";
export var wrapCarouselActive = "menuComponent-module--wrapCarouselActive--cabf4";