import React from "react";
import { Link } from "gatsby";
import { Container } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImageProps } from "helpers/getImgProps";
import eatnactFrame from "images/eatnactFrame.png";
import apple from "images/apple.png";
import playmarket from "images/playmarket.png";
import * as styles from "./comingSoon.module.scss";

function ComingSoonSection({ image, content: { title, body } }) {
  return (
    <Container fluid id="get-app-section" className={styles.container}>
      <div className={styles.appBg}>
        <img src={eatnactFrame} alt="eatnact frame" className={styles.frame} />
        <div className={styles.appText}>
          <p className={styles.comingSoonTitle}>{title}</p>
          <p className={styles.comingSoonBody}>{body}</p>
          <div className={styles.buttons}>
            <Link
              to="https://play.google.com/store/apps/details?id=com.eatnact.delivery"
              target="_blank"
            >
              <div className={`${styles.android}`}>
                <div className={styles.icon}>
                  <img src={playmarket} alt="play market" />
                </div>
                <div>
                  <p className={styles.buttonTopText}>Available on the</p>
                  <p className={styles.buttonBottomText}>Google Play</p>
                </div>
              </div>
            </Link>
            <Link
              to="https://apps.apple.com/am/app/eatnact-delivery/id6448895224"
              target="_blank"
            >
              <div className={`${styles.ios}`}>
                <div className={styles.icon}>
                  <img src={apple} alt="app store" />
                </div>
                <div>
                  <p className={styles.buttonTopText}>Download on</p>
                  <p className={styles.buttonBottomText}>Apple Store</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className={styles.appMockup}>
          <div className={styles.appMockupImg}>
            <GatsbyImage
              {...getImageProps(image)}
              className={styles.gatsbyImage}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ComingSoonSection;
