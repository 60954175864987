// extracted by mini-css-extract-plugin
export var address = "location-module--address--fb535";
export var addressImg = "location-module--addressImg--f93e2";
export var addressList = "location-module--addressList--d8dd9";
export var branchName = "location-module--branchName--a820e";
export var description = "location-module--description--530da";
export var locations = "location-module--locations--cf938";
export var map = "location-module--map--aed64";
export var mapAddressInfo = "location-module--mapAddressInfo--7e391";
export var mapAndAddress = "location-module--mapAndAddress--048a5";
export var phone = "location-module--phone--53d86";
export var selected = "location-module--selected--32ace";
export var title = "location-module--title--71ced";