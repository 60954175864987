import React from "react";
import { Container } from "react-bootstrap";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./mission.module.scss";

function MissionSection({ bgTop, missionImg, title, subtitle, body }) {
  return (
    <Container
      fluid
      className={styles.mission}
      style={{
        backgroundImage: "url(" + bgTop.file.localFile.url + ")",
      }}
    >
      <div
        className={`${styles.missionInfo} d-flex flex-column-reverse flex-md-row justify-content-md-around align-items-md-center}`}
      >
        <div className={`${styles.missionImg}`} md={5}>
          <img {...getImgProps(missionImg.file)} />
          <div className={`${styles.missionEmptyBox}`}></div>
        </div>
        <div className={`${styles.missionText}`} md={5}>
          <p className={`${styles.title}`}>{title}</p>
          <p className={`${styles.body}`}>{subtitle}</p>
          <br />
          <p className={`${styles.body}`}>{body}</p>
        </div>
      </div>
    </Container>
  );
}

export default MissionSection;
