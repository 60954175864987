import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";
import SpecialComponent from "./specialComponent";
import { Container, Col, Row } from "react-bootstrap";
import * as styles from "./special.module.scss";
import { getImageProps } from "helpers/getImgProps";

function SpecialSection({ specialComponent, title, specialImg }) {
  const specialComponentData = sortArrayOfObjects(specialComponent).map(
    ({ icon, title, body }, index) => {
      return (
        <SpecialComponent
          key={index}
          icon={icon}
          title={title}
          description={body}
        />
      );
    }
  );
  return (
    <Container fluid className={styles.special}>
      <Row>
        <Col
          lg={{ span: 2, offset: 2 }}
          md={{ span: 1, offset: 1 }}
          className="no-padding no-margin"
        ></Col>
        <Col>
          <p className={`${styles.specialTitle}`}>{title}</p>
        </Col>
      </Row>
      <Row className={styles.specialPosition}>
        <Col lg={8} md={12} sm={12} xs={12} className="no-padding no-margin">
          {specialComponentData}
        </Col>
        <Col
          lg={4}
          md={12}
          sm={12}
          xs={12}
          className={styles.specialImageWrapper}
        >
          <div className={styles.specialImg}>
            <GatsbyImage {...getImageProps(specialImg)} />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SpecialSection;
